import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faHtml5,
	faCss3,
	faSass,
	faJsSquare,
	faReact,
	faNodeJs
} from '@fortawesome/free-brands-svg-icons'
import { faDatabase } from '@fortawesome/free-solid-svg-icons'
import '../styles/components/Skills.scss'

const Skills = () => {
	return (
		<div
			className="skills"
			data-sal="slide-up"
			data-sal-delay="400"
			data-sal-duration="1000"
			data-sal-easing="ease-out-bounce"
		>
			<h3 className="h3-secondary-text">Skills I am Familiar with</h3>
			<div className="skills__icons">
				<div className="icon icon-purple">
					<FontAwesomeIcon icon={faHtml5} />
				</div>
				<div className="icon icon-red">
					<FontAwesomeIcon icon={faCss3} />
				</div>
				<div className="icon icon-purple">
					<FontAwesomeIcon icon={faSass} />
				</div>
				<div className="icon icon-red">
					<FontAwesomeIcon icon={faJsSquare} />
				</div>
				<div className="icon icon-purple">
					<FontAwesomeIcon icon={faReact} />
				</div>
				<div className="icon icon-red">
					<FontAwesomeIcon icon={faNodeJs} />
				</div>
				<div className="icon icon-purple">
					<FontAwesomeIcon icon={faDatabase} />
				</div>
			</div>
		</div>
	)
}

export default Skills
