import React from 'react'
import { Link } from 'react-router-dom'
import '../styles/components/ProjectCard.scss'

const ProjectCard = ({
  animationDelay,
  project: { id, name, githubLink, projectLink, thumbnail }
}) => {
  return (
    <div
      className={`projects project--${id}`}
      data-sal='slide-up'
      data-sal-delay={animationDelay}
      data-sal-duration='1000'
      data-sal-easing='ease-out-bounce'>
      <div className='projects__side projects__side--front'>
        <img src={thumbnail} alt={`${name} Project poster`} />
      </div>
      <div className='projects__side projects__side--back'>
        <Link to={`/project/${id}`} className='projects__btn projects--yellow'>
          View more
        </Link>
        <a
          rel='noopener noreferrer'
          target='_blank'
          href={projectLink}
          className='projects__btn projects--purple'>
          Go to website
        </a>
        {githubLink && (
          <a
            rel='noopener noreferrer'
            target='_blank'
            href={githubLink}
            className='projects__btn projects--red'>
            View Github
          </a>
        )}
      </div>
    </div>
  )
}

export default ProjectCard
