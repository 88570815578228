import React, { useState, useEffect } from 'react'

import logoImage from '../img/mylogo.png'
import { Link } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faGithub,
  faLinkedinIn,
  faTwitter
} from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'

import '../styles/components/NavBarResponsive.scss'

const NavBarResponsive = ({ specialStyle }) => {
  const [navBarState, setNavBarState] = useState(false)

  useEffect(() => {
    if (navBarState)
      document.getElementsByTagName('html')[0].style.overflow = 'hidden'
    else document.getElementsByTagName('html')[0].style.overflow = 'visible'
  }, [navBarState])

  const handleClick = () => setNavBarState(!navBarState)

  return (
    <div className='navigation'>
      <Link to='/' onClick={() => setNavBarState(false)}>
        <img className='naviation--logo' src={logoImage} alt='Website Logo' />
      </Link>
      <input
        type='checkbox'
        checked={navBarState}
        onChange={handleClick}
        className='navigation__checkbox'
        id='navigation-toggle'
      />
      <label
        htmlFor='navigation-toggle'
        className='navigation__button'
        style={specialStyle && { background: 'white' }}>
        <div className='navigation__hamburger'></div>
      </label>
      <div className='navigation__background'></div>
      <nav className='hero-area__navigation--sm'>
        <ul className='navigation__list'>
          <li className='navigation__item'>
            <a
              onClick={() => setNavBarState(false)}
              style={specialStyle}
              href='/#about'
              className='ab'>
              About
            </a>
          </li>
          <li className='navigation__item'>
            <a
              onClick={() => setNavBarState(false)}
              style={specialStyle}
              href='/#services'
              className='sc'>
              Services
            </a>
          </li>
          <li className='navigation__item'>
            {' '}
            <a
              onClick={() => setNavBarState(false)}
              style={specialStyle}
              href='/#projects'
              className='port'>
              Projects
            </a>
          </li>
          <li className='navigation__item'>
            {' '}
            <a
              onClick={() => setNavBarState(false)}
              style={specialStyle}
              href='/#contact'
              className='con'>
              Contact
            </a>
          </li>
        </ul>
        <div className='navigation__icons'>
          <a
            onClick={() => setNavBarState(false)}
            style={specialStyle}
            target='blank'
            href='https://github.com/sunguru98'>
            {' '}
            <FontAwesomeIcon icon={faGithub} />
          </a>
          <a
            onClick={() => setNavBarState(false)}
            style={specialStyle}
            target='blank'
            href='https://www.linkedin.com/in/sundeepcharanramkumar/'>
            {' '}
            <FontAwesomeIcon icon={faLinkedinIn} />
          </a>
          <a
            onClick={() => setNavBarState(false)}
            style={specialStyle}
            target='blank'
            href='https://twitter.com/SundeepCharan'>
            {' '}
            <FontAwesomeIcon icon={faTwitter} />
          </a>
          <a
            onClick={() => setNavBarState(false)}
            style={specialStyle}
            target='blank'
            href='mailto:webdevdesign@sundeepcharan.com'>
            {' '}
            <FontAwesomeIcon icon={faEnvelope} />
          </a>
        </div>
      </nav>
    </div>
  )
}

export default NavBarResponsive
