import React, { Fragment, useEffect } from 'react'

import HeroArea from '../sections/HeroArea'
import AboutArea from '../sections/AboutArea'
import ServicesArea from '../sections/ServicesArea'
import ProjectsArea from '../sections/ProjectsArea'
import ContactArea from '../sections/ContactArea'

import 'sal.js/dist/sal.css'
import sal from 'sal.js/dist/sal'

const HomePage = () => {
  useEffect(() => {
    sal()
  })
  return (
    <Fragment>
      <HeroArea />
      <AboutArea />
      <ServicesArea />
      <ProjectsArea />
      <ContactArea />
    </Fragment>
  )
}

export default HomePage
