import React from 'react'
import logoImage from '../img/mylogo.png'
import { Link } from 'react-router-dom'
import '../styles/components/NavBar.scss'

const NavBar = ({ specialStyle }) => {
	return (
		<nav className="hero-area__navigation">
			<Link to='/'>
				<img className="naviation--logo" src={logoImage} alt="Website Logo" />
			</Link>
			<ul className="navigation--links">
				<li>
					<a style={specialStyle} href="/#about" className="abl">
						About
					</a>
				</li>
				<li>
					<a style={specialStyle} href="/#services" className="scl">
						Services
					</a>
				</li>
				<li>
					{' '}
					<a style={specialStyle} href="/#projects" className="portl">
						Projects
					</a>
				</li>
				<li>
					{' '}
					<a style={specialStyle} href="/#contact" className="conl">
						Contact
					</a>
				</li>
			</ul>
		</nav>
	)
}

export default NavBar
