import React from 'react'
import '../styles/pages/HomePage/ServicesArea.scss' 

const ServicesArea = () => {
	return (
		<section id="services" className="services">
			<div className="polytl bgservice"></div>
			<div className="polytr bgservice2"></div>
			<span className="circles c8"></span>
			<span className="circles c10"></span>
			<div className="container">
				<h2 className="h2-primary-text">Services</h2>
				<div className="services-container">
					<div
						className="service service--1"
						data-sal="slide-up"
						data-sal-delay="300"
						data-sal-duration="1000"
						data-sal-easing="ease-out-bounce"
					>
						<i className="fas fa-pencil-alt"></i>
						<h3 className="h3-tertiary-text">Landing Pages</h3>
						<p>
							Beautifully <span className="yellow">handcrafted</span> landing pages
							built using the latest technologies that engage your audience{' '}
						</p>
					</div>
					<div
						className="service service--2"
						data-sal="slide-up"
						data-sal-delay="400"
						data-sal-duration="1000"
						data-sal-easing="ease-out-bounce"
					>
						<i className="fas fa-code purple"></i>
						<h3 className="h3-tertiary-text purple">Ecommerce Applications</h3>
						<p>
							Creating full stack eCommerce applications from scratch including
							payment gateways and Admin panel
						</p>
					</div>
					<div
						className="service service--3"
						data-sal="slide-up"
						data-sal-delay="500"
						data-sal-duration="1000"
						data-sal-easing="ease-out-bounce"
					>
						<i className="fas fa-database"></i>
						<h3 className="h3-tertiary-text">SPAs / Blogs</h3>
						<p>
							Designing single page applications which require Fetching APIs
							from third party services or just blogs which help your
							documenting and SEO purposes{' '}
						</p>
					</div>
				</div>
			</div>
		</section>
	)
}

export default ServicesArea
