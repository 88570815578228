import React, { useState } from 'react'
import '../styles/pages/HomePage/ProjectsArea.scss'

import ProjectCard from '../components/ProjectCard'

import mowheeThumbnail from '../img/mowheeThumbnail.png'
import devConnectorThumbnail from '../img/devConnectorThumbnail.png'
import shopmateThumbnail from '../img/shopmateThumbnail.png'
import reactColourPickerThumbnail from '../img/reactColourThumbnail.png'
import sleeverMartThumbnail from '../img/sleeverMartThumbnail.png'
import medeasyThumbnail from '../img/medeasyThumbnail.png'

const ProjectsArea = () => {
	const [projects] = useState([
		{
			id: 1,
			name: 'Medeasy',
			projectLink: 'https://medeasyonline.com',
			thumbnail: medeasyThumbnail
		},
		{
			id: 2,
			name: 'Devconnector',
			githubLink: 'https://github.com/sunguru98/Devconnector',
			projectLink: 'https://merndevconnector.herokuapp.com',
			thumbnail: devConnectorThumbnail
		},
		{
			id: 3,
			name: 'Shopmate',
			githubLink: 'https://github.com/sunguru98/Shopmate',
			projectLink: 'https://shopmateapp.netlify.com',
			thumbnail: shopmateThumbnail
		},
		{
			id: 4,
			name: 'Sleever Mart',
			githubLink: 'https://github.com/sunguru98/Sleever-Mart',
			projectLink: 'https://sleevermart.netlify.com',
			thumbnail: sleeverMartThumbnail
		},
		{
			id: 5,
			name: 'React colour picker',
			githubLink: 'https://github.com/sunguru98/Flat-UI-colors-clone',
			projectLink: 'https://reactcolourpicker.netlify.com',
			thumbnail: reactColourPickerThumbnail
		},
		{
			id: 6,
			name: 'Mowhee',
			githubLink: 'https://github.com/sunguru98/Mowhee',
			projectLink: 'https://mowhee.netlify.com',
			thumbnail: mowheeThumbnail
		}
	])

	return (
		<section id="projects" className="portfolio">
			<div className="polytr bgport"></div>
			<div className="polybr cbr"></div>
			<div className="container">
				<h2 className="h2-primary-text">My projects</h2>
			</div>
			<div className="projects-container">
				{projects.map(project => (
					<ProjectCard key={project.id} animationDelay={project.id * 100} project={project} />
				))}
			</div>
		</section>
	)
}

export default ProjectsArea
