import React, { useEffect } from 'react'
import projects from '../projects'

import NavBar from '../components/NavBar'
import NavBarResponsive from '../components/NavBarResponsive'
import ProjectCarousel from '../components/ProjectCarousel'
import '../styles/pages/ProjectDetailPage/DetailArea.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import 'sal.js/dist/sal.css'
import sal from 'sal.js/dist/sal'

const ProjectDetailPage = ({
  match: {
    params: { projectId }
  }
}) => {
  useEffect(() => {
    sal()
  })

  const {
    projectName,
    projectGithub,
    projectWebsite,
    photos,
    technologies,
    projectDescription1,
    projectDescription2
  } = projects.find(p => p.id === parseInt(projectId))

  let specialMessage = null

  if (projectId === '1')
    specialMessage = (
      <span
        style={{
          display: 'block',
          color: '#FECA57',
          fontWeight: 'bold'
        }}>
        (NOTE : The application works best on desktop and laptops. Please do
        view on your desktops / laptops and since the client strictly said not
        to open source the project, I cannot show you the github for this.
        However you can very well contact me for the technologies used here in
        detail. Thank you for your understanding and regrets for the
        inconvenience :))<br></br>
      </span>
    )
  else if (projectId === '2')
    specialMessage = (
      <span
        style={{
          display: 'block',
          color: '#FECA57',
          fontWeight: 'bold'
        }}>
        (NOTE : Since the application is hosted on heroku, I highly request you
        to bare the initial loading time, as Heroku's free tier makes their put
        into sleep and that should not be confused with the performance of the
        application. Thank you for your understanding, and deep regrets for the
        inconvenience :))<br></br>
      </span>
    )
  else if (projectId === '5')
    specialMessage = (
      <span
        style={{
          display: 'block',
          color: '#FECA57',
          fontWeight: 'bold'
        }}>
        (NOTE : The Movie database API is laggy in some regions, hence I request
        you to reload twice for the config data to load properly. Thanks :))
        <br></br>
      </span>
    )
  else if (projectId === '3' || projectId === '4')
    specialMessage = (
      <span
        style={{
          display: 'block',
          color: '#FECA57',
          fontWeight: 'bold'
        }}>
        (NOTE : It is highly recommended to view this application on a desktop /
        laptop to view it's optimal performance. Thank you for your
        understanding, and deep regrets for the inconvenience :))
        <br></br>
      </span>
    )

  return (
    <section className='detail'>
      <div className='polytl bghero'></div>
      <div className='container-detail'>
        <NavBar specialStyle={{ color: '#fff', fontWeight: 500 }} />
        <NavBarResponsive specialStyle={{ color: '#fff', fontWeight: 500 }} />
        <div className='description'>
          <div
            className='description-text'
            data-sal='slide-up'
            data-sal-delay='300'
            data-sal-duration='1000'
            data-sal-easing='ease-out-bounce'>
            <h1 className='h1-primary-text' style={{ color: '#fff' }}>
              {projectName}
            </h1>
            <p>
              {specialMessage}
              <br></br>
              {projectDescription1}
            </p>
            <p
              style={{
                fontSize: '1.65rem',
                lineHeight: '1.9',
                margin: '2rem 0 3rem 0'
              }}>
              {projectDescription2}
            </p>
            <h1
              className='h1-primary-text text-2'
              style={{ color: '#fff', fontSize: '3rem' }}>
              Technologies used
            </h1>
            <div className='skills__icons'>
              {technologies.map((tech, index) => (
                <div key={index} className='icon'>
                  {typeof tech === 'string' ? (
                    <img
                      alt='technology'
                      style={{ width: '80%', height: '80%' }}
                      src={tech}
                    />
                  ) : (
                    <FontAwesomeIcon icon={tech} style={{ fontSize: '5rem' }} />
                  )}
                </div>
              ))}
            </div>
          </div>
          <ProjectCarousel
            photos={photos}
            github={projectGithub}
            website={projectWebsite}
          />
        </div>
      </div>
    </section>
  )
}

export default ProjectDetailPage
