import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown } from '@fortawesome/free-solid-svg-icons'

import NavBarResponsive from '../components/NavBarResponsive'
import NavBar from '../components/NavBar'
import SocialLinks from '../components/SocialLinks'
import '../styles/pages/HomePage/HeroArea.scss'

import codingPic from '../img/codingpic.png'
import downArrow from '../img/downarrow.png'
import resume from '../resume.pdf'

const HeroArea = () => {
	return (
		<header id='hero-area' className="hero-area">
			<div className="polytl bghero"></div>
			<span className="circles c1"></span>
			<span className="circles c2"></span>
			<span className="circles c3"></span>
			<span className="circles c4"></span>
			<div className="polybr cbr"></div>
			<div className="container">
				<NavBarResponsive />
				<NavBar />
				<div className="hero-area__hero-text">
					<img
						src={codingPic}
						alt="Person with monitors illustration"
						className="hero-area__coding-image"
					/>
					<div className="hero-area__hero-text--details">
						<h1 className="h1-primary-text">Sundeep</h1>
						<h1 className="h1-secondary-text">Charan Ramkumar</h1>
						<h3 className="h3-primary-text">MERN Stack developer</h3>
						<a href="#contact" className="hero-area__contact-me">
							Contact me
							<img src={downArrow} alt="Down arrow" />
						</a>
						<a
                            target="_blank"
                            rel="noopener noreferrer"
							href={resume}
							className="hero-area__resume"
						>
							View Resume
						</a>
					</div>
				</div>
				<div className="ycircle hero-area__next">
					<a href="/#about" className="down">
						<FontAwesomeIcon icon={faArrowDown} />
					</a>
				</div>
			</div>
			<SocialLinks />
		</header>
	)
}

export default HeroArea
