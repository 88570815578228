import React, { useState } from 'react'
import '../styles/components/ProjectCarousel.scss'

const ProjectCarousel = ({ photos, github, website }) => {
  const images = photos
  const [imageIndex, setImageIndex] = useState(0)
  const handleClick = val => setImageIndex(val)
  return (
    <div
      className='ProjectCarousel'
      data-sal='slide-up'
      data-sal-delay='600'
      data-sal-duration='1000'
      data-sal-easing='ease-out-bounce'>
      <div className='slides'>
        <img
          style={{ width: '100%', height: '100%' }}
          src={images[imageIndex]}
          alt=''
        />
        <div className='slides__toggles'>
          {images.map((image, index) => (
            <div
              onClick={() => handleClick(index)}
              key={index}
              className={`slides__toggle ${
                index === imageIndex ? 'active' : ''
              }`}>
              <div className={`slides__toggle--inner`}></div>
            </div>
          ))}
        </div>
      </div>
      <div className='buttons'>
        <a
          rel='noreferrer noopener'
          target='_blank'
          href={website}
          className='button button-yellow'
          style={{ marginRight: '2rem' }}>
          Go to website
        </a>
        {github && (
          <a
            rel='noreferrer noopener'
            target='_blank'
            href={github}
            className='button button-red'>
            View Github
          </a>
        )}
      </div>
    </div>
  )
}

export default ProjectCarousel
