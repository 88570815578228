import mowheeimg1 from './img/mowhee/1.png'
import mowheeimg2 from './img/mowhee/2.png'
import mowheeimg3 from './img/mowhee/3.png'
import mowheeimg4 from './img/mowhee/4.png'

import sleeverMartimg1 from './img/sleeverMart/1.png'
import sleeverMartimg2 from './img/sleeverMart/2.png'
import sleeverMartimg3 from './img/sleeverMart/3.png'
import sleeverMartimg4 from './img/sleeverMart/4.png'

import devConnectorimg1 from './img/devconnector/1.png'
import devConnectorimg2 from './img/devconnector/2.png'
import devConnectorimg3 from './img/devconnector/3.png'
import devConnectorimg4 from './img/devconnector/4.png'

import reactColourimg1 from './img/reactColourPicker/1.png'
import reactColourimg2 from './img/reactColourPicker/2.png'
import reactColourimg3 from './img/reactColourPicker/3.png'
import reactColourimg4 from './img/reactColourPicker/4.png'

import shopmateimg1 from './img/shopmate/1.png'
import shopmateimg2 from './img/shopmate/2.png'
import shopmateimg3 from './img/shopmate/3.png'
import shopmateimg4 from './img/shopmate/4.png'

import medeasyimg1 from './img/medeasy/1.png'
import medeasyimg2 from './img/medeasy/2.png'
import medeasyimg3 from './img/medeasy/3.png'
import medeasyimg4 from './img/medeasy/4.png'

import {
  faHtml5,
  faCss3,
  faJsSquare,
  faReact,
  faVuejs,
  faNodeJs,
  faGithub
} from '@fortawesome/free-brands-svg-icons'

import { faDatabase } from '@fortawesome/free-solid-svg-icons'
import firebase from './img/firebase.svg'
import redux from './img/redux.svg'

export default [
  {
    id: 1,
    photos: [medeasyimg1, medeasyimg2, medeasyimg3, medeasyimg4],
    technologies: [
      faHtml5,
      faCss3,
      faReact,
      redux,
      faNodeJs,
      faGithub,
      faDatabase
    ],
    projectName: 'Medeasy',

    projectDescription1:
      'Medeasy is a MERN Stack application which helps people to buy medicines from the convenience at their home.',

    projectDescription2:
      'The Authorization is handled via Bcrypt.js module, And stateless JWT authentication is implemented. Front end has a complex module of reusable components, pages and routing using React-Router. There are about 4 payment gateways used. Credit / Debit card using Razorpay, Paypal, Bitcoin using Coinbase commerce and Western Union. Orders and Shipment status are emailed to customers automatically with the help of Nodemailer. Backend is extensively created as headless API, which would aid in future expansions. MongoDB Atlas is used for database. The hosting is done via Digital Ocean with Nginx load balancer / Reverse proxy with SSL certificate.',

    projectWebsite: 'https://medeasyonline.com'
  },
  {
    id: 2,
    photos: [
      devConnectorimg1,
      devConnectorimg2,
      devConnectorimg3,
      devConnectorimg4
    ],
    technologies: [
      faHtml5,
      faCss3,
      faReact,
      redux,
      faNodeJs,
      faGithub,
      faDatabase
    ],
    projectName: 'Devconnector',
    projectGithub: 'https://github.com/sunguru98/Devconnector',

    projectDescription1:
      'Devconnector is a MERN Stack application which helps developers to present their profiles, and post articles on current software development.',

    projectDescription2:
      'The Authorization is handled via Bcrypt.js module, And stateless JWT authentication is implemented. Regarding the application, the state is splitted into profile, auth, alerts, posts reducers. Since it involves asynchronous operations over the state actions, Redux Thunk is used here (although I have plans to upgrade to Redux Saga). The deployment is done via Heroku and Environment variables are used using dotenv module. Overall it is a Full stack application that highly depends on any basic state management techniques and CRUD Operations using REST API',

    projectWebsite: 'https://merndevconnector.herokuapp.com'
  },
  {
    id: 3,
    photos: [shopmateimg1, shopmateimg2, shopmateimg3, shopmateimg4],
    technologies: [faHtml5, faCss3, faVuejs, faNodeJs, faGithub, faDatabase],
    projectName: 'Shopmate',
    projectGithub: 'https://github.com/sunguru98/Shopmate',

    projectDescription1:
      "Shopmate is a full fledged eCommerce application, where I handled the front end part from scratch using Vue.js, VUEX, Vue-Router. It's backend API can viewed @ https://backend.turing.com/docs",

    projectDescription2:
      'The application is all about selling T-shirts to customers via Stripe payments. The tokens are being via JWT Bearer authentication and actions are mutated via VUEX store, I had personally used Stripe Elements, because they were a tad bit elegant when compared with "Pay with Stripe Button". A Cart Id is generated a stored in localStorage, and is used through the entire transaction. Orders are generated when user is brought to the payment page.',

    projectWebsite: 'https://shopmateapp.netlify.com'
  },
  {
    id: 4,
    photos: [
      sleeverMartimg1,
      sleeverMartimg2,
      sleeverMartimg3,
      sleeverMartimg4
    ],
    technologies: [faHtml5, faCss3, faReact, redux, firebase],
    projectName: 'Sleever Mart',
    projectGithub: 'https://github.com/sunguru98/Sleever-Mart',

    projectDescription1:
      'Sleever Mart is a full stack eCommerce application, built with React.js and Redux as the front end and Firebase Authentication and Firestore as the backend.',

    projectDescription2:
      'The application is all about selling apparel to customers, The authentication is handled by Fireabse using the onAuth callback listener, and also supports GOOGLE OAUTH authentication. Cart state and other mutable state are being handled via Redux. Some of the styles are used from styled-components as to differentiate the pros of it, but in majority SCSS is used for styling using node-sass compiler. I have further plans to migrate this application towards a GraphQL server, and will definitely update once it is complete. Till then it has all the necessary things a basic eCommerce front end should have.',

    projectWebsite: 'https://sleevermart.netlify.com'
  },
  {
    id: 5,
    photos: [
      reactColourimg1,
      reactColourimg2,
      reactColourimg3,
      reactColourimg4
    ],
    technologies: [faHtml5, faCss3, faJsSquare, faReact, faGithub],
    projectName: 'React Colour Picker',
    projectGithub: 'https://github.com/sunguru98/Flat-UI-colors-clone',

    projectDescription1:
      'React Colour picker, is an attempted clone of the famous flatuicolors.com, which provides users curated color pallets for their design schemes.',

    projectDescription2:
      'This application is all about, enabling users create color pallets and copy the colour codes in one of 4 available formats (HEX, #HEX, RGB, RGBA). The application has a predefined set of pallets to choose from and each color in a pallet, has about 8 shades of saturation, to help pick the accurate accent. Users can change the hue via a slider present in the Top left of the application. The primary UI used for development is Material-UI. I had tried to replicate it as much as possible both from User interface side and as well as the working of it.',

    projectWebsite: 'https://reactcolourpicker.netlify.com'
  },
  {
    id: 6,
    photos: [mowheeimg1, mowheeimg2, mowheeimg3, mowheeimg4],
    technologies: [faHtml5, faCss3, faJsSquare, faGithub],
    projectName: 'Mowhee',
    projectGithub: 'https://github.com/sunguru98/Mowhee',

    projectDescription1:
      'Mowhee is a single page application project where users can search for movies and tv shows by query, category and can like their favourite movies, So that they do not need to search their movies all over again in the forest',

    projectDescription2:
      'The movies data are taken from an API of THE MOVIES DATABASE. Using Asynchronous Javascript (Axios) the details are fetched and then displayed. Depending upon the receivers internet connection they receive all the required details. A detail view of the movie is also present where the cast and crew, runtime, year of release and the ratings are shown. The trailer of the respective movie can be found in youtube by just clicking the "View Trailer" button',

    projectWebsite: 'https://mowhee.netlify.com'
  }
]
