import React from 'react'
import proffResponsiveImage from '../img/proffresponsive-min.png'
import '../styles/components/PhotoCircle.scss'

const PhotoCircle = () => {
	return (
		<div className="photocircle">
			<img src={proffResponsiveImage} alt="Sundeep Charan Ramkumar" />
		</div>
	)
}

export default PhotoCircle
