import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp } from '@fortawesome/free-solid-svg-icons'
import '../styles/pages/HomePage/ContactArea.scss'

const ContactArea = () => {
	const [formState, setFormState] = useState({
		name: '',
		email: '',
		message: ''
	})

	const encode = data => {
		return Object.keys(data)
			.map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
			.join('&')
	}

	const { name, email, message } = formState
	const handleChange = event =>
		setFormState({ ...formState, [event.target.name]: event.target.value })

	const handleSubmit = e => {
		fetch('/', {
			method: 'POST',
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
			body: encode({ 'form-name': 'contact', ...formState })
		})
			.then(() => {
				alert(
					'Message successfully sent. Thank you for your interest ! I will revert back shortly.'
				)
				setFormState({ name: '', email: '', message: '' })
			})
			.catch(error => alert(error))

		e.preventDefault()
	}

	return (
		<footer id="contact" className="contact">
			<div className="polytr ctr"></div>
			<div className="polybl cbl"></div>
			<div className="container">
				<h2 className="h2-primary-text">Have something to talk about ?</h2>
				<form onSubmit={handleSubmit} className="contact__form">
					<input
						onChange={handleChange}
						value={name}
						required
						type="text"
						placeholder="Name"
						name="name"
					/>
					<input
						onChange={handleChange}
						value={email}
						required
						type="email"
						placeholder="Email"
						name="email"
					/>
					<textarea
						onChange={handleChange}
						value={message}
						required
						cols="30"
						rows="10"
						placeholder="Your message"
						name="message"
					></textarea>
					<input type="submit" value="Let's Talk" />
				</form>
				<div className="contact__top ycircle">
					<a rel="nofollow" href="/#hero-area" className="up">
						<FontAwesomeIcon icon={faArrowUp} />
					</a>
				</div>
			</div>
		</footer>
	)
}

export default ContactArea
