import React from 'react'

import Skills from '../components/Skills'
import PhotoCard from '../components/PhotoCard'
import PhotoCircle from '../components/PhotoCircle'
import '../styles/pages/HomePage/AboutArea.scss'

const AboutArea = () => {
  return (
    <section id='about' className='about'>
      <div className='polytl bgab'></div>
      <div className='polybr bgabbot'></div>
      <div className='polytl ctr'></div>
      <span className='circles c5'></span>
      <span className='circles c6'></span>
      <span className='circles c7'></span>
      <div className='container'>
        <h2 className='h2-primary-text'>About me</h2>
        <div
          className='about-container'
          data-sal='slide-up'
          data-sal-delay='300'
          data-sal-duration='1000'
          data-sal-easing='ease-out-bounce'>
          <PhotoCard />
          <PhotoCircle />
          <div className='paragraph-container'>
            <p>
              Hi. I am a MERN Stack developer for about{' '}
              <span className='purple'>
                {new Date().getFullYear() -
                  new Date('01/01/2017').getFullYear() +
                  1}{' '}
                years
              </span>{' '}
              ranging from small scale <span className='purple'>websites</span>{' '}
              to large scale{' '}
              <span className='purple'>eCommerce web applications</span>. (Stripe, Paypal, Coinbase commerce payment gateway integrations too !)
            </p>
            <p className='p2'>
              It all started in 2017 where I first learnt to build{' '}
              <span className='purple'>static landing pages</span>. Then I came
              to know about how servers work with the help of{' '}
              <span className='purple'>
                JAVA, Python, PHP and now currently Node.js
              </span>
              . That expanded my view upon the possibilities of creating huge
              applications{' '}
            </p>
            <p className='p3'>
              Then I slowly started engaging myself building single page
              application development, state management techniques using{' '}
              <span className='purple'>Redux.js and Context API and sideeffects using Redux-thunk and Redux-Saga</span>, building
              microservices in{' '}
              <span className='purple'>Node.js and Express.js</span> and knowing
              how to connect to databases using{' '}
              <span className='purple'>MongoDB and MySQL</span>.
            </p>
          </div>
        </div>
        <Skills />
      </div>
    </section>
  )
}

export default AboutArea
