import React from 'react'
import myProffImage from '../img/myproffphoto.png'
import '../styles/components/PhotoCard.scss'

const PhotoCard = () => {
	return (
		<div className="photocard">
			<div className="yellow-rectangle"></div>
			<div className="photo-space">
				<img src={myProffImage} alt="Sundeep Charan Ramkumar" />
			</div>
		</div>
	)
}

export default PhotoCard
