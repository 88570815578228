import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faLinkedin,
	faGithub,
	faTwitter
} from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'

const SocialLinks = () => {
	return (
		<div className="hero-area__icons">
			<a target="blank" href="https://github.com/sunguru98">
				{' '}
				<FontAwesomeIcon icon={faGithub} />
			</a>
			<a
				target="blank"
				href="https://www.linkedin.com/in/sundeepcharanramkumar/"
			>
				{' '}
				<FontAwesomeIcon icon={faLinkedin} />
			</a>
			<a target="blank" href="https://twitter.com/SundeepCharan">
				{' '}
				<FontAwesomeIcon icon={faTwitter} />
			</a>
			<a target="blank" href="mailto:webdevdesign@sundeepcharan.com">
				{' '}
				<FontAwesomeIcon icon={faEnvelope} />
			</a>
			<span className="line"></span>
		</div>
	)
}

export default SocialLinks
