import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import HomePage from './pages/HomePage'
import ProjectDetailPage from './pages/ProjectDetailPage'

const App = () => {
  return (
    <Switch>
      <Route exact path='/' component={HomePage} />
      <Route exact path='/project/:projectId' component={ProjectDetailPage} />
      <Redirect to='/' />
    </Switch>
  )
}

export default App;
